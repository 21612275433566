import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import GetRecords from "../components/records"
import roundPoints from "../utils/round-points"
import Layout from "../components/layout"

function SingleStat({ title, winner, stat }) {
  return (
    <Box p={2}>
      <Paper>
        <Box display="flex" justifyContent="center">
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="h6" gutterBottom>
            {winner}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" gutterBottom>
            {stat}
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}

function MatchupStat({ title, data }) {
  return (
    <Box p={2}>
      <Paper>
        <Box display="flex" justifyContent="center">
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="h6" gutterBottom>
            {data.winnerName} ({data.winnerPoints}) vs {data.loserName} (
            {data.loserPoints})
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" gutterBottom>
            {data.season} | {data.week}
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}

function TableStat({ title, data }) {
  return (
    <Box p={2}>
      <Paper>
        <Box display="flex" justifyContent="center">
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
        </Box>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Season</TableCell>
                <TableCell align="center">Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow key={row.season} hover>
                  <TableCell align="center" style={{ width: "33%" }}>
                    {row.ownerName}
                  </TableCell>
                  <TableCell align="center" style={{ width: "33%" }}>
                    {row.week ? row.season + " | " + row.week : row.season}
                  </TableCell>
                  <TableCell align="center" style={{ width: "33%" }}>
                    {row.seasonPointsFor
                      ? roundPoints(row.seasonPointsFor)
                      : roundPoints(row.points)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}

export default function Records() {
  const {
    mostPointsOverall,
    mostPointsBySeason,
    mostPointsSingleGame,
    mostPointsSingleGameBySeason,
    biggestBlowout,
    closestGame,
  } = GetRecords()

  return (
    <Layout>
      {console.log(mostPointsOverall)}
      <SingleStat
        title="Points Overall"
        winner={mostPointsOverall.name}
        stat={roundPoints(mostPointsOverall.seasonPointsFor)}
      />
      <TableStat
        title="Points in a Season"
        statName="Points"
        data={mostPointsBySeason}
      />
      <SingleStat
        title="Points in a Game"
        winner={`${mostPointsSingleGame.ownerName} | ${mostPointsSingleGame.season} | ${mostPointsSingleGame.week}`}
        stat={roundPoints(mostPointsSingleGame.points)}
      />
      <TableStat
        title="Points in a Game by Season"
        statName="Points"
        data={mostPointsSingleGameBySeason}
      />
      <MatchupStat title="Biggest Blowout" data={biggestBlowout} />
      <MatchupStat title="Closest Game" data={closestGame} />
    </Layout>
  )
}
