import { useStaticQuery, graphql } from "gatsby"

function getTeams(owners) {
  return owners
    .map(owner => owner.teams.items)
    .reduce((acc, val) => acc.concat(val), [])
}

function getSeasons(teams) {
  return [
    ...new Set(
      teams.map(team => team.season).reduce((acc, val) => acc.concat(val), [])
    ),
  ]
}

function getHighestPoints(items) {
  return items
    .map(owner => owner.seasonPointsFor)
    .sort((a, b) => a - b)
    .pop()
}

function getHighestPointsOwner(items, points) {
  return items.filter(item => item.seasonPointsFor === points)[0]
}

function getMostPointsOverall(owners) {
  const points = getHighestPoints(owners)
  const maxOwner = getHighestPointsOwner(owners, points)
  return { ...maxOwner }
}

function getMostPointsBySeason(teams, seasons) {
  let results = []
  seasons.forEach(season => {
    const seasonTeams = teams.filter(team => team.season === season)
    const seasonPoints = getHighestPoints(seasonTeams)
    const seasonMaxOwner = getHighestPointsOwner(seasonTeams, seasonPoints)
    results.push({ ...seasonMaxOwner })
  })
  return results
}

function checkGame(game, results) {
  if (game.homeTeamPoints > results.points) {
    results.points = game.homeTeamPoints
    results.ownerName = game.homeTeam.ownerName
    results.season = game.season
    results.week = game.week
  }
  if (game.awayTeamPoints > results.points) {
    results.points = game.awayTeamPoints
    results.ownerName = game.awayTeam.ownerName
    results.season = game.season
    results.week = game.week
  }
  return results
}

function getMostPointsSingleGame(games) {
  let results = { points: 0, ownerName: null, season: null, week: null }
  games.forEach(game => {
    results = checkGame(game, results)
  })
  return results
}

function getMostPointsSingleGameBySeason(games, seasons) {
  let results = []
  seasons.forEach(season => {
    let seasonResults = { points: 0, ownerName: null, season: null, week: null }
    const seasonGames = games.filter(game => game.season === season)
    seasonGames.forEach(game => {
      seasonResults = checkGame(game, seasonResults)
    })
    results.push({ ...seasonResults })
  })
  return results
}

function winnersAndLosers(game) {
  if (game.homeTeamPoints > game.awayTeamPoints) {
    return {
      winnerName: game.homeTeam.ownerName,
      winnerPoints: game.homeTeamPoints,
      loserName: game.awayTeam.ownerName,
      loserPoints: game.awayTeamPoints,
      season: game.season,
      week: game.week,
    }
  } else {
    return {
      winnerName: game.awayTeam.ownerName,
      winnerPoints: game.awayTeamPoints,
      loserName: game.homeTeam.ownerName,
      loserPoints: game.homeTeamPoints,
      season: game.season,
      week: game.week,
    }
  }
}

function getBiggestBlowout(games) {
  let widestMargin = 0
  let blowoutGame
  games.forEach(game => {
    if (Math.abs(game.homeTeamPoints - game.awayTeamPoints) > widestMargin) {
      blowoutGame = game
      widestMargin = Math.abs(game.homeTeamPoints - game.awayTeamPoints)
    }
  })
  return winnersAndLosers(blowoutGame)
}

function getClosestGame(games) {
  let narrowMargin = 1000
  let closeGame
  games.forEach(game => {
    if (
      Math.abs(game.homeTeamPoints - game.awayTeamPoints) < narrowMargin &&
      Math.abs(game.homeTeamPoints - game.awayTeamPoints) !== 0
    ) {
      closeGame = game
      narrowMargin = Math.abs(game.homeTeamPoints - game.awayTeamPoints)
    }
  })
  return winnersAndLosers(closeGame)
}

export default function GetRecords() {
  const data = useStaticQuery(graphql`
    query getRecords {
      ccffl {
        listOwners {
          items {
            name
            seasonPointsFor
            teams {
              items {
                ownerName
                season
                seasonPointsFor
              }
            }
          }
        }
      }
      ccffl {
        listGames(
          limit: 100000
          filter: { isBsbGame: { eq: false }, isPlayoffGame: { eq: false } }
        ) {
          items {
            awayTeamPoints
            homeTeamPoints
            season
            week
            awayTeam {
              ownerName
            }
            homeTeam {
              ownerName
            }
          }
        }
      }
    }
  `)

  const owners = data.ccffl.listOwners.items
  const games = data.ccffl.listGames.items
  const teams = getTeams(owners)
  const seasons = getSeasons(teams)

  const mostPointsOverall = getMostPointsOverall(owners)
  const mostPointsBySeason = getMostPointsBySeason(teams, seasons)
  const mostPointsSingleGame = getMostPointsSingleGame(games)
  const mostPointsSingleGameBySeason = getMostPointsSingleGameBySeason(
    games,
    seasons
  )
  const biggestBlowout = getBiggestBlowout(games)
  const closestGame = getClosestGame(games)

  return {
    mostPointsOverall,
    mostPointsBySeason,
    mostPointsSingleGame,
    mostPointsSingleGameBySeason,
    biggestBlowout,
    closestGame,
  }
}
